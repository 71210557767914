@font-face {
  font-family: "GoogleSans";
  src: url("./assets/css/fonts/GoogleSans-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "GoogleSans";
  src: url("./assets/css/fonts/GoogleSans-Medium.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "GoogleSans";
  src: url("./assets/css/fonts/GoogleSans-MediumItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: "GoogleSans";
  src: url("./assets/css/fonts/GoogleSans-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "GoogleSans";
  src: url("./assets/css/fonts/GoogleSans-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "GoogleSans";
  src: url("./assets/css/fonts/GoogleSans-BoldItalic.ttf") format("truetype");
  font-weight: 700;
  font-style: italic;
}

body {
  font-family: "GoogleSans", helvetica, arial, sans-serif;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;

  background: #feba0a;
  color: #fff;
}

h1 {
  font-style: italic;
  color: #ff6f00;
}
.h-100 {
  height: 100vh !important;
}

.removed {
  display: none;
}

#status {
  font-size: 150%;
}

.slide {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100vw;
  height: 100vh;
  background: #feba0a;
  opacity: 0;
  z-index: -1;
  display: none;
  transition: all 0.5s ease;
  transform: translateY(5vh);
}
.slide.pre-active {
  display: block;
  z-index: 9;
}
.slide.active {
  z-index: 9;
  opacity: 1;
  display: block;
  visibility: visible;
  transform: translateY(0);
}
.footer {
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 5vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.top-text {
  font-size: 1.5vw;
  text-align: center;
  top: 5vh;
  display: none;
  position: absolute;
  transition: all 0.5s ease;
  left: 0px;
  right: 0px;
  text-align: center;
  opacity: 1;
  transform: translateY(0);
}
.first-text,
.second-text,
.fourth-text {
  transform: translate(-50%, 0);
  position: absolute;
  bottom: 15vh;
  text-align: center;
  color: #fff;
  font-size: 1.2vw;
  opacity: 1;
  left: 50%;
}
.fourth-text {
  bottom: 21vh;
}
.first-text.inactive,
.second-text.inactive,
.fourth-text.inactive {
  transform: translate(-50%, -50px);
  opacity: 0;
}
.button-left {
  position: relative;

  display: inline-block;
  bottom: 0vh;
  height: 4vw;
}
.button-right {
  position: relative;
  display: inline-block;
  bottom: 0vw;
  height: 4vw;
}
.button-right.button-circle.skipAnim {
  width: 4vw !important;
  transition: all 1s ease;
  white-space: nowrap;
}
.button-right.button-circle.skipAnim svg {
  transform: translateX(1vw);
  transition: all 1s ease;
}
button.button-right.button-circle svg {
  margin-right: 0px;
}
.button-right.button-circle span {
  transition: all 1s ease;
  opacity: 0;
  position: relative;
  display: inline-block;
  transform: translateX(50%);
}
.button-center {
  bottom: 0vh;
  position: relative;
  height: 4vw;
}
button.big-button-center {
  bottom: 0vh;
  line-height: 4.5vh;
  font-size: 1.6vw;
  padding: 0px 1.5vw;
  position: relative;
  margin: 0px 2vw;
  height: 4.5vw;
}
button.button.skipAnim {
  bottom: 0vh;
  line-height: 4.4vh;
  font-size: 1.5vw;
  padding: 0px 1.5vw;
  position: relative;
  margin: 0px 2vw;
  height: 4vw;
}
button.button.skipAnim {
  width: auto;
}
button.big-button-center svg,
.button.skipAnim svg {
  margin-right: 1vw;
}
button.big-button-center.button-circle {
  padding: 0px;
}
button.big-button-center.button-circle svg {
  margin-right: 0vw;
}
button.footer-big-button {
  height: 4vw;
}

button {
  background: #fdf9ef;
  color: #feba0a;
  border: 0px;
  transition: all 0.3s ease;
  font-size: 2vw;
  border-radius: 5vw;
  height: 4vw;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3);
  line-height: 4.2vw;
  padding: 0px 1vw;
  font-weight: 300;
}
button:focus {
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.3);
}
button.dataCollector:focus {
  box-shadow: none;
}
button.dataCollector {
  color: #ce0707;
  padding: 0px 2vw;
  margin: 0px 2vw;
  transform-origin: center;
  transition: all 0.5s ease;
  transform: scale(1);
}
button.dataCollector.inactive {
  transform: scale(0);
}
button.dataCollector * {
  pointer-events: none;
}
button.dataCollector.animuj {
  animation: buttonCollector 0.8s linear infinite;
}
@keyframes buttonCollector {
  0% {
    color: #ce0707;
  }
  50% {
    color: #33a853;
  }
  100% {
    color: #ce0707;
  }
}
button.dataCollector.animuj .seconds-text,
button.dataCollector.animuj .seconds-desc {
  display: block;
}
button.dataCollector.animuj .record-text {
  visibility: hidden;
}

.seconds-text {
  font-size: 2.5vw;
  position: absolute;
  left: 50%;
  top: 50%;
  display: none;
  transform: translate(-45%, -50%);
  font-weight: 900;
}
.seconds-desc {
  display: none;
  line-height: 1vw;
  font-size: 0.5vw;
  position: absolute;
  right: 1.2vw;
  top: 50%;
  transform: translateY(-50%);
}
button.button-circle {
  width: 2.5vw;
  height: 2.5vw;
  padding: 0px !important;
  line-height: 2.5vw;
  font-size: 1vw;
}
button.button-right,
.button.button-left {
  height: 2.5vw;
  line-height: 2.5vw;
  font-size: 1vw;
  width: 6vw;
}
button.button-right svg,
.button.button-left svg {
  margin-right: 0.8vw;
}

button.button-circle.big-circle {
  width: 10vh;
  height: 10vh;
  line-height: 10.5vh;
  font-size: 6vh;
}

button.footer-small-button {
  height: 2.5vw;
  line-height: 2.5vw;
  font-size: 1vw;
}
.cytat {
  font-size: 1.65vw;
  font-weight: bold;
}
.cytat span {
  display: block;
  text-align: right;
  margin-top: 20px;
  font-weight: 400;
}

.video-wrap {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(0.8);
  width: 80vw;
  height: auto;
  transition: all 0.5s ease;
}
.video-wrap.active {
  transform: translate(-50%, -50%) scale(0.8);
}
.video-wrap video {
  /* object-fit: cover; */
  background: #feba0a;

  width: 100%;
  /* height: 100%; */
}
.video-dialog-buttons {
  position: absolute;
  left: 50%;
  bottom: 5vw;
  width: 25vw;
  text-align: center;
  transform: translate(-50%, 3vh);
  transition: all 0.5s ease;
  opacity: 0;
}
.video-dialog-buttons.active {
  transform: translate(-50%, 0);
  opacity: 1;
}
.video-dialog {
  background: #feba0a;
  border-radius: 20px;
  padding: 1vw;
  font-size: 1vw;
  text-align: center;
  position: relative;
  transform: translateY(-1vw);
}
.video-dialog-buttons button {
  margin: 0px 10px;
}
#webcam-wrap {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -55%);

  transition: all 0.5s ease;
}
#webcam {
  object-fit: cover;
  max-width: 60vw;
  width: 40vw;
  max-height: 80vw;
  position: relative;
  margin: 0px;
  border: 0.2vw solid #fff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  border-radius: 2vw;
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg); /* Safari and Chrome */
  -moz-transform: rotateY(180deg); /* Firefox */
}
#video-over {
  position: absolute;
  left: 0px;
  border-radius: 2vw;
  right: 0px;
  bottom: 5px;
  top: 0px;
  background: rgba(255, 255, 255, 0.8);
  z-index: 5;
  opacity: 0;
}
#video-over.animuj {
  animation: animuj 0.4s linear infinite;
}
@keyframes animuj {
  0% {
    opacity: 0;
    backdrop-filter: blur(0px);
  }
  30% {
    opacity: 1;
    backdrop-filter: blur(10px);
  }
  60% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}
.relatywny {
  position: relative;
}
.tuto {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 15%;
  max-width: 300px;
}
.tuto img {
  width: 100%;
  transform: scale(1);
  opacity: 0;
}
.slide.active .tuto img {
  animation: tutoimg 2s linear;
}
@keyframes tutoimg {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes tutoimg1 {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
.slide.active .tuto:nth-child(2) img {
  animation-delay: 2s;
}
.slide.active .tuto:nth-child(3) img {
  animation-delay: 4s;
}
.slide.active .tuto:nth-child(4) img {
  animation: tutoimg1 1s ease;
  animation-delay: 6s;

  animation-fill-mode: forwards;
}
.tutorialOver {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
}
.tutorialOver div {
  position: absolute;
  width: 17%;
  left: 0px;
  top: 0px;
  height: 100%;
  opacity: 1;
  background: #feba0a;
  transition: all 0.6s ease;
}
.tuto:nth-child(2) img {
  transition-delay: 0.3s;
}
.tutorialOver div:nth-child(3) {
  left: 48%;
  width: 30%;
  transition-delay: 0.6s;
}
.tutorialOver div:nth-child(4) {
  left: 79%;
  width: 30%;
  transition-delay: 0.9s;
}
.slide.active .tutorialOver div {
  opacity: 0;
}

#pics {
  position: absolute;
  right: 15px;
  border-radius: 2vw;

  top: 15px;
  width: 17%;
  z-index: 10;
  height: 20%;
}
#result {
  z-index: 10;
  position: absolute;
  top: 22%;
  right: 15px;
  width: 17%;
  text-align: center;
}
#pics canvas {
  border-radius: 1vw;
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg); /* Safari and Chrome */
  -moz-transform: rotateY(180deg); /* Firefox */
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3);
}
#pics.animuj {
  animation: animpics 0.4s linear infinite;
}
@keyframes animpics {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  40% {
    opacity: 1;
    transform: scale(1.1);
  }
  50% {
    opacity: 1;
    transform: scale(1);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}
.timer {
  color: #feba0a;
  position: absolute;
  left: 100%;
  transform: translateX(-130%);
  top: 20px;
  font-size: 2vw;
  z-index: 9999;
}

.train3.train-results {
  position: absolute;
  top: 100%;
  left: 50%;
  margin-top: 7vh;
  width: 100%;
  transform: translateX(-50%);
}
.waitOver,
.waitOver0,
.waitOver1 {
  position: absolute;
  background: #feba0a;
  left: 0px;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 99999;
  text-align: center;
  font-size: 1.8vw;
  justify-content: center;
  align-items: center;
  display: flex;
}
.resulto {
  position: absolute;
  border: 0.2vw solid #fff;
  border-radius: 0.5vw;
  display: inline-block;
  height: 5vh;
  width: 40%;
  left: 0;
  top: 0;
  transition: all 0.2s ease;
}
.resulto-prog,
.resulto-desc,
.resulto span {
  transition: all 0.2s ease;
}
.resulto.winner {
  border: 0.2vw solid #33a853;
}
.resulto.winner span {
  color: #fff;
}
.resulto.winner .resulto-prog {
  background: #33a853;
}
.resulto.winner .resulto-desc {
  color: #33a853;
}
.resulto.loser {
  border: 0.2vw solid #ea433b;
}
.resulto.loser span {
  color: #fff;
}
.resulto.loser .resulto-prog {
  background: #ea433b;
}
.resulto.loser .resulto-desc {
  color: #ea433b;
}
.resulto span {
  position: absolute;
  left: 40px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  color: #feba07;
  font-size: 1vw;
}
.resulto-prog {
  position: absolute;
  left: -1px;
  top: -1px;
  border-radius: 0.2vw 0 0 0.2vw;
  transform: scale(1.02);

  bottom: -1px;
  background: #fff;
}
.resulto#result1 .resulto-prog {
  left: auto;
  border-radius: 0 0.2vw 0.2vw 0;
  right: -1px;
}
.resulto#result1 {
  right: 0px;
  left: auto;
}
.resulto#result1 span {
  left: auto;
  right: 40px;
}
.resulto .resulto-desc {
  position: absolute;
  right: 100%;
  margin-right: 1vw;
  top: 50%;
  transform: translateY(-50%);
  white-space: nowrap;
  color: #fff;
  font-size: 1vw;
}
.resulto .resulto-img {
  position: absolute;
  left: 100%;
  width: 4vw;
  border-radius: 0.2vw;
  top: 50%;
  transform: translateY(-50%);
  margin-left: 1vw;
}
.resulto .resulto-img:after {
  position: absolute;
  left: -0.1vw;
  right: -0.1vw;
  bottom: -0.1vw;
  z-index: 999;
  top: -0.1vw;
  border-radius: 0.5vw;
  content: " ";
  border: 0.2vw solid #ffffff;
}
.resulto.loser .resulto-img:after {
  border-color: #ea433b;
}
.resulto.winner .resulto-img:after {
  border-color: #33a853;
}

.resulto-img canvas {
  position: relative;
  width: 100%;
  border: 0px;
  vertical-align: middle;
  border-radius: 0.5vw;
  border-width: 0px !important;
}
.resulto#result0 .resulto-img {
  left: auto;
  right: 100%;
  margin-left: 0px;
  margin-right: 1vw;
}
.resulto#result1 {
  right: 0px;
  left: auto;
}
.resulto#result1 .resulto-desc {
  right: auto;
  left: 100%;
  margin-left: 1vw;
}

.progress-val {
  font-size: 1vw;
}
.progress-wrap {
  border: 0.2vw solid #fff;
  display: inline-block;
  position: relative;
  height: 2.5vw;
  width: 15vw;
  border-radius: 0.3vw;
}
.progress-wrap-in {
  position: absolute;
  left: -0.1vw;
  top: -0.1vw;
  transform-origin: left center;
  bottom: -0.1vw;
  right: 0vw;
  overflow: hidden;

  transform: scale(1.01);
}
.progress-in {
  position: absolute;
  left: -0.1vw;
  top: -0.1vw;
  transform-origin: left center;
  bottom: -0.1vw;
  right: -0.1vw;
  transform: translateX(-100%) scale(1);
  transition: all 0.5s linear;
  background: #fff;
  border-radius: 0.3vw 0 0 0.3vw;
}
